/**
 * --------------------------------------------
 * AdminLTE DaynightSW.js
 * License MIT
 * --------------------------------------------
 */

import $ from 'jquery'

/**
 * Constants
 * ====================================================
 */

const NAME = 'DaynightSW'
const DATA_KEY = 'lte.daynightsw'
const JQUERY_NO_CONFLICT = $.fn[NAME]

const SELECTOR_DATA_WIDGET = '[data-widget="daynightsw"]'
const SELECTOR_ICON = `${SELECTOR_DATA_WIDGET} i`

const Default = {
    dayIcon: 'fa-sun-o',
    nightIcon: 'fa-moon-o',
}

/**
 * Class Definition
 * ====================================================
 */

class DaynightSW {
    constructor(_element, _options) {
        this.element = _element
        this.options = _options
    }

    // Public

    toggle() {
        if ($('body').hasClass('dark-mode')) {
            this.setDayMode()
        } else {
            this.setNightMode()
        }
    }

    setDayMode() {
        $('body').removeClass('dark-mode')
        $('.main-sidebar').removeClass('sidebar-dark-primary').addClass('sidebar-light-primary')
        $('.main-header').removeClass('bg-dark navbar-dark border-bottom-0').addClass('avbar-white navbar-light')
        $(SELECTOR_ICON).removeClass(this.options.dayIcon).addClass(this.options.nightIcon)

        $.removeCookie('dark-mode',{ path: '/' });
    }

    setNightMode() {
        $('body').addClass('dark-mode');
        $('.main-sidebar').removeClass('sidebar-light-primary').addClass('sidebar-dark-primary')
        $('.main-header').removeClass('navbar-white navbar-light').addClass('bg-dark navbar-dark border-bottom-0')
        $(SELECTOR_ICON).removeClass(this.options.nightIcon).addClass(this.options.dayIcon)

        $.cookie('dark-mode', 1, { expires: 365, path: '/' })
    }

    // Static
    static _jQueryInterface(config) {
        return this.each(function () {
            let data = $(this).data(DATA_KEY)
            const _config = $.extend({}, Default, typeof config === 'object' ? config : $(this).data())

            if (!data) {
                data = new DaynightSW($(this), _config)
                $(this).data(DATA_KEY, data)
            } else if (typeof config === 'string') {
                if (typeof data[config] === 'undefined') {
                    throw new TypeError(`No method named "${config}"`)
                }
            }

            data[config]()
        })
    }
}

/**
 * Data API
 * ====================================================
 */
$(document).on('click', SELECTOR_DATA_WIDGET, function () {
    DaynightSW._jQueryInterface.call($(this), 'toggle')
})

$(document).ready(function () {
    if ($.cookie('dark-mode')) {
        DaynightSW._jQueryInterface.call($(this), 'setNightMode')
    }
})

/**
 * jQuery API
 * ====================================================
 */

$.fn[NAME] = DaynightSW._jQueryInterface
$.fn[NAME].Constructor = DaynightSW
$.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return DaynightSW._jQueryInterface
}

export default DaynightSW
